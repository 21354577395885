<template>
  <div class="change-password">
    <el-dialog
      :visible="centerDialogVisible"
      width="600px"
      center
      :show-close="false"
    >
      <template slot="title">
        <div class="title">修改密码</div>
      </template>
      <div class="content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item prop="oldPassWord">
            <template slot="label">
              <span class="label">原密码：</span>
            </template>
            <el-input
              v-model.trim="ruleForm.oldPassWord"
              type="text"
              clearable
              placeholder="请输入原密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="newPassWord">
            <template slot="label">
              <span class="label">新密码：</span>
            </template>
            <el-input
              v-model.trim="ruleForm.newPassWord"
              show-password
              clearable
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="再次输入密码：" prop="enterPassWord">
            <template slot="label">
              <span class="label">再次输入密码：</span>
            </template>
            <el-input
              v-model.trim="ruleForm.enterPassWord"
              show-password
              clearable
              placeholder="请再次输入新密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeChangePassword">取消修改</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="enterChangePassword"
          >确认修改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updatePassWord } from "@/api/user";
export default {
  name: "changePassword",
  props: {
    centerDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (this.ruleForm.oldPassWord == value) {
        callback(new Error("新密码和旧密码不能一致"));
      } else if (
        this.ruleForm.enterPassWord &&
        value != this.ruleForm.enterPassWord
      ) {
        callback(new Error("新密码和再次输入的密码不一致"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (this.ruleForm.newPassWord && value != this.ruleForm.newPassWord) {
        callback(new Error("新密码和再次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPassWord: "",
        newPassWord: "",
        enterPassWord: "",
      },
      rules: {
        oldPassWord: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassWord: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 8,
            message: "密码长度不能少于8个字符",
            trigger: "blur",
          },
          { validator: validatePass1, trigger: "blur" },
        ],
        enterPassWord: [
          { required: true, message: "请输入再次确认的密码", trigger: "blur" },
          {
            min: 8,
            message: "密码长度不能少于8个字符",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    enterChangePassword() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          updatePassWord({
            oldPassWord: this.ruleForm.oldPassWord,
            newPassWord: this.ruleForm.newPassWord,
          })
            .then(({ data }) => {
              if (data.code == 0) {
                this.loading = false;
                this.$message.success("修改密码成功,即将退出重新登录");
                this.$emit("enter");
              } else {
                this.loading = false;
                this.$message.error(data.msg);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeChangePassword() {
      this.$refs.ruleForm.resetFields();
      this.$emit("close");
      this.ruleForm = {
        oldPassWord: "",
        newPassWord: "",
        enterPassWord: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 5px;
}

::v-deep .el-dialog__body {
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-bottom: 20px;
}

.change-password {
  .title {
    text-align: center;
    color: #000;
    font-size: 20px;
  }

  .content {
    .label {
      color: #000;
    }
  }
}
.ruleForm {
  padding: 0 60px 0 40px;
}
</style>
