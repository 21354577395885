<template>
    <div class="rootView homeView">
        <div class="indexView" style="height: 100%;">
            <div class="leftView">
                <LeftMenu></LeftMenu>
            </div>
            <div class="rightView">
                <TopMenu/>
                <div class="content">
                    <router-view :key="key"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeftMenu from "@/layout/LeftMenu";
import TopMenu from "@/layout/TopMenu";
import store from "../store";
import {getToken} from "@/utils/auth"; // get token from cookie
import {getLater} from "@/api/clothes/clothes";
import versionTood from "@/utils/versionUpdate.js";

export default {
    name: "index",
    components: {
        LeftMenu,
        TopMenu,
    },
    data() {
        return {
            notifications: [],
            notificationList: [],
            storeId: null,
        };
    },
    computed: {
        key() {
            return this.$route.path + Math.random();
        },
    },
    watch: {
        $route() {
            versionTood.isNewVersion().then((res) => {
                if (!res) {
                    this.$alert('存在新的版本，请更新最新的版本!', '警告', {
                        confirmButtonText: '确定',
                        showClose: false,
                        type: 'error',
                        callback: () => {
                            window.location.reload();
                        }
                    });
                }
            });
        },
    },
    mounted() {
        //监听浏览器关闭事件
        // window.addEventListener('beforeunload', function(e) {
        //   //窗口关闭前
        //   return (e.returnValue = '确认要关闭当前窗口？');
        // });
        // window.onbeforeunload = function() {
        //   //窗口关闭前
        //   return '确认要关闭当前窗口？';
        // }
        if (getToken()) {
            const userInfo = store.getters.userInfo;
            this.storeId = userInfo.storeId;
            this.connWebsocket(userInfo.storeId);
        }
        // this.openMessage()
    },
    methods: {

        connWebsocket(storeId) {
            let _this = this;
            let ws = new WebSocket("wss://socket.xetcloud.com/webSocket");
            ws.onopen = () => {
                console.log("数据发送中...");
                ws.send(
                    JSON.stringify({
                        // 连接成功将，用户ID传给服务端
                        storeId: storeId,
                    })
                );
            };
            ws.onmessage = (evt) => {
                console.log("数据已接收:", evt.data);
                let res = JSON.parse(evt.data);
                if (res.code !== 100) {
                    let type = res.data.remindType;
                    if (type === 1) {
                        _this.openMessageTips(res.data);
                        this.audio.btts("您有小程序订单,请立即处理");
                    }
                    if (type === 3) {
                        _this.openMessage(res.data);
                        this.audio.btts("您有一条瑕疵订单，请立即处理");
                    }
                    if (type === 4) {
                        _this.afterSalesConfirmation(res.data);
                        this.audio.btts("新增一条售后消息，请查看");
                    }
                }
            };
            ws.onclose = function () {
                // 关闭 websocket
                console.log("连接已关闭...");
            };
            // 组件销毁时调用，中断websocket链接
            this.over = () => {
                ws.close();
            };
        },
        // 瑕疵确认
        openMessage(message) {
            let _this = this;
            _this.closeAllNotification();
            _this.notifications[message.key] = this.$notify({
                title: "瑕疵确认",
                // position: 'bottom-right',
                dangerouslyUseHTMLString: true,
                duration: 0,
                customClass: "fixedNoti",
                message: this.$createElement("div", {style: {marginTop: "10px"}}, [
                    this.$createElement(
                        "div",
                        {style: {fontSize: "15px"}},
                        `${message.reminderTime}`
                    ),
                    this.$createElement(
                        "div",
                        {style: {fontSize: "15px"}},
                        `${message.factoryName}工厂发送瑕疵确认订单，请及时处理`
                    ),
                    this.$createElement(
                        "div",
                        {style: {marginTop: "10px", float: "right"}},
                        [
                            this.$createElement(
                                "el-button",
                                {
                                    attrs: {type: "info", size: "mini"},
                                    on: {
                                        click: _this.laterHandle.bind(
                                            _this,
                                            1,
                                            1,
                                            message.noticeId
                                        ),
                                    },
                                },
                                "稍后处理"
                            ),
                            this.$createElement(
                                "el-button",
                                {
                                    attrs: {type: "primary", size: "mini"},
                                    on: {
                                        click: _this.onceHandle.bind(_this, 1, 2, message.noticeId),
                                    },
                                },
                                "立刻处理"
                            ),
                        ]
                    ),
                ]),
            });
            console.log(_this.notifications);
        },
        //售后确认
        afterSalesConfirmation(message) {
            let _this = this;
            _this.closeAllNotification();
            _this.notifications[message.key] = this.$notify({
                title: "售后订单",
                // position: 'bottom-right',
                dangerouslyUseHTMLString: true,
                duration: 0,
                customClass: "fixedNoti",
                message: this.$createElement("div", {style: {marginTop: "10px"}}, [
                    this.$createElement(
                        "div",
                        {style: {fontSize: "15px"}},
                        `${message.reminderTime}`
                    ),
                    this.$createElement(
                        "div",
                        {style: {fontSize: "15px"}},
                        `${message.content}`
                    ),
                    this.$createElement(
                        "div",
                        {style: {marginTop: "10px", float: "right"}},
                        [
                            this.$createElement(
                                "el-button",
                                {
                                    attrs: {type: "info", size: "mini"},
                                    on: {click: _this.laterHandleClose.bind(_this, 1, 1)},
                                },
                                "稍后查看"
                            ),
                            this.$createElement(
                                "el-button",
                                {
                                    attrs: {type: "primary", size: "mini"},
                                    on: {click: _this.afterSalesTreatment.bind(_this, 1, 2)},
                                },
                                "立刻查看"
                            ),
                        ]
                    ),
                ]),
            });
            console.log(_this.notifications);
        },
        //稍后处理
        laterHandleClose() {
            this.closeAllNotification();
        },
        //稍后处理
        laterHandle(prames1, prames2, noticeId) {
            console.log(noticeId, prames1, prames2);
            const params = new URLSearchParams();
            params.append("noticeId", noticeId);
            params.append("type", 1);
            getLater(params).then((res) => {
                console.log(res);
            });
            this.closeAllNotification();
        },
        //立刻处理
        onceHandle(noticeId, prames1, prames2) {
            console.log(noticeId, prames1, prames2);
            const params = new URLSearchParams();
            params.append("noticeId", prames2);
            params.append("type", 2);
            getLater(params).then((res) => {
                console.log(res);
            });
            this.closeAllNotification();
            this.$router.push({
                path: "/home/more/flaw",
            });
        },
        //关闭单个通知
        closeNotification(id, operateCode, message) {
            this.notifications[message.key].close();
            delete this.notifications[message.key];
        },
        //关闭所有通知
        closeAllNotification() {
            let _this = this;
            for (let key in _this.notifications) {
                _this.notifications[key].close();
                delete _this.notifications[key];
            }
        },
        //打开一个新的通知
        openMessageTips(message) {
            let _this = this;
            this.closeAllOrderNotification();
            //将messageId和通知实例放入字典中
            this.notificationList[message.key] = this.$notify({
                title: "订单通知",
                position: "top-right",
                showClose: false,
                dangerouslyUseHTMLString: true,
                message: this.$createElement(
                    "div",
                    {
                        style: {
                            width: "285px",
                        },
                    },
                    [
                        this.$createElement("div", null, [
                            this.$createElement(
                                "span",
                                null,
                                "新增门店小程序预约订单，点击查看详情"
                            ),
                        ]),
                        this.$createElement(
                            "div",
                            {
                                style: {
                                    textAlign: "right",
                                },
                            },
                            [
                                this.$createElement(
                                    "el-button",
                                    {
                                        attrs: {
                                            type: "text",
                                        },
                                        on: {
                                            click: _this.closeOrderNotification.bind(
                                                _this,
                                                1,
                                                1,
                                                message
                                            ),
                                        },
                                    },
                                    "暂不处理"
                                ),
                                this.$createElement(
                                    "el-button",
                                    {
                                        attrs: {
                                            type: "primary",
                                            size: "mini",
                                        },
                                        style: {
                                            marginLeft: "20px",
                                        },
                                        on: {
                                            click: _this.handleReserve.bind(_this, 1, 2, message),
                                        },
                                    },
                                    "查看详情"
                                ),
                            ]
                        ),
                    ]
                ),
                duration: 0,
            });
        },
        //关闭单个通知
        closeOrderNotification(id, operateCode, message) {
            this.notificationList[message.key].close();
            delete this.notificationList[message.key];
        },
        // 关闭所有订单提醒
        closeAllOrderNotification() {
            let _this = this;
            for (let key in _this.notificationList) {
                _this.notificationList[key].close();
                delete _this.notifications[key];
            }
        },
        handleReserve() {
            this.closeAllOrderNotification();
            this.$router.push({path: "/home/reserve"});
        },
        //售后通知立即处理
        afterSalesTreatment() {
            this.laterHandleClose();
            this.$router.push({path: "/home/more/afterSale"});
        },
    },
};
</script>
<style>
::-webkit-scrollbar {
    /*滚动条整体样式*/
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    background-color: rgba(0, 21, 41, 0.08);
    border-radius: 5px;
}
</style>
<style scoped lang="scss">
.homeView {
  position: relative;
  min-width: 768px;
  height: 100%;
  background-color: #e7e9f1;
  overflow: hidden;

  .indexView {
    display: flex;

    .leftView {
      width: 70px;
    }

    .rightView {
      height: 100%;
      width: calc(100vw - 70px);

      .content {
        margin: 10px;
      }

      // padding-left: 76px;
    }
  }
}

/*.rootView {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
</style>
