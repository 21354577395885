<template>
  <div class="top-menu">
    <div class="title">洗衣通智能收银系统</div>
    <div class="container">
      <div
        class="item"
        @click="$router.push({ path: '/home/refundOrder' })"
      >
      <el-badge
          :value="homepageData.storeRefundNoViewNum"
          class="item"
          :max="99"
          :hidden="homepageData.storeRefundNoViewNum <= 0"
        >
          <span class="el-icon-phone-outline icon"></span>
          退款订单
          <span class="line"></span>
        </el-badge>
       
      </div>
      <div
        class="item"
        @click="$router.push({ path: '/home/more/retailing' })"
      >
        <span class="item">
          <span class="el-icon-phone-outline icon"></span>
          商品零售
          <span class="line"></span>
        </span>
      </div>
      <div
        class="item"
        @click="$router.push({ path: '/home/more/backWashing' })"
      >
        <el-badge
          :value="homepageData.refundNoViewNum"
          class="item"
          :max="99"
          :hidden="homepageData.refundNoViewNum <= 0"
        >
          <span class="el-icon-phone-outline icon"></span>
          衣物退洗
          <span class="line"></span>
        </el-badge>
      </div>
      <div class="item" @click="$router.push({ path: '/home/more/complaint' })">
        <el-badge
          :value="homepageData.suggestionNum"
          class="item"
          :max="99"
          :hidden="homepageData.suggestionNum <= 0"
        >
          <span class="el-icon-phone-outline icon"></span>
          投诉建议
          <span class="line"></span>
        </el-badge>
      </div>
      <div class="item" @click="$router.push({ path: '/home/more/afterSale' })">
        <el-badge
          :value="homepageData.afterSaleNum"
          class="item"
          :max="99"
          :hidden="homepageData.afterSaleNum <= 0"
        >
          <span class="el-icon-service icon"></span>
          售后订单
          <span class="line"></span>
        </el-badge>
      </div>
      <div class="item" @click="$router.push({ path: '/home/more/reminders' })">
        <el-badge
          :value="homepageData.remindersNum"
          class="item"
          :hidden="homepageData.remindersNum <= 0"
        >
          <span class="el-icon-service icon"></span>
          催单订单
          <span class="line"></span>
        </el-badge>
      </div>
      <div class="item" @click="$router.push({ path: '/home/more/flaw' })">
        <el-badge
          :value="homepageData.flawNum"
          class="item"
          :hidden="homepageData.flawNum <= 0"
        >
          <span class="el-icon-service icon"></span>
          工厂瑕疵
          <span class="line"></span>
        </el-badge>
      </div>
      <div class="item" @click="$router.push({ path: '/home/reserve' })">
        <el-badge
          :value="homepageData.reservationNum"
          class="item"
          :hidden="homepageData.reservationNum <= 0"
        >
          <span class="el-icon-alarm-clock icon"></span>
          预约
          <span class="line"></span>
        </el-badge>
      </div>
      <div class="item" @click="$router.push({ path: '/home/more/draftbox' })">
        <el-badge
          :value="homepageData.draftBoxClothesNum"
          class="item"
          :hidden="homepageData.draftBoxClothesNum <= 0"
        >
          <span class="el-icon-document icon"></span>
          草稿箱
        </el-badge>
      </div>
      <div class="item">
        <el-popover
          placement="bottom-start"
          width="250"
          trigger="click"
          v-model="popoverValue"
        >
          <div
            style=" color: rgba(16, 16, 16, 100);
        font-size: 14px"
          >
            <div style="padding-bottom:5px">
              登录门店: {{ userInfo.storeName }}
            </div>
            <div style="padding-bottom:5px">登录账号: {{ userInfo.phone }}</div>
            <div style="padding-bottom:15px">
              店长名称: {{ userInfo.realName }}
            </div>
            <div
              class="button"
              style="display:flex; justify-content: space-between;padding:0 20px"
            >
              <el-button
                @click="changePassword"
                size="small"
                style="background:#3370ff;color:#fff"
                >修改密码</el-button
              >
              <el-button
                @click="handleLogout"
                size="small"
                style="background:#3370ff;color:#fff"
                >退出登录</el-button
              >
            </div>
          </div>
          <div slot="reference" class="content">
            <span>
              <el-avatar
                :src="
                  userData.avator
                    ? userData.avator
                    : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
                "
                size="small"
              ></el-avatar>
            </span>
            <span>{{ userData.realName }}</span>
            <span class="el-icon-arrow-down icon"></span>
          </div>
        </el-popover>
      </div>
      <!-- 修改密码 -->
      <ChangePassword
        :centerDialogVisible="centerDialogVisible"
        @close="closeChangePassword"
        @enter="enterChangePassword"
      />
    </div>
  </div>
</template>

<script>
import { getHomeHeadData } from "@/api/clothes/order";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import store from "../store";
import { mapGetters } from "vuex";
import ChangePassword from "./components/changePassword";
export default {
  components: {
    ChangePassword,
  },
  data() {
    return {
      homepageData: {},
      timeStart: dateFormat(new Date()),
      timeEnd: dateFormat(new Date()),
      centerDialogVisible: false,
      storeId: null,
      userData: {},
      popoverValue: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    if (getToken()) {
      const userInfo = store.getters.userInfo;
      this.getHomeHeadData(userInfo.storeId);
      this.userData = userInfo;
    }
  },
  watch: {
    $route() {
      if (getToken()) {
        const userInfo = store.getters.userInfo;
        this.getHomeHeadData(userInfo.storeId);
      }
    },
  },
  methods: {
    // 获取角标数量
    getHomeHeadData(storeId) {
      const params = new URLSearchParams();
      params.append("beginTime", this.timeStart);
      params.append("endTime", this.timeEnd);
      params.append("tenantId", storeId);
      getHomeHeadData(params)
        .then(({ data }) => {
          if (data.code === 0) {
            this.homepageData = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleLogout() {
      this.popoverValue = false;
      // 退出登录
      this.$confirm("是否退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.$router.push({path: '/login'})
          this.$store.dispatch("user/logout").then(() => {
            location.reload();
          });
        })
        .catch(() => {});
    },
    // 打开修改密码
    changePassword() {
      this.centerDialogVisible = true;
      this.popoverValue = false;
    },
    // 关闭修改密码
    closeChangePassword() {
      this.centerDialogVisible = false;
    },
    enterChangePassword() {
      this.centerDialogVisible = false;
      this.$store.dispatch("user/logout").then(() => {
        location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-menu {
  background-color: #fff;
  color: rgb(51, 51, 51);
  display: flex;
  width: 100%;
  //   flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  .title {
    color: #3370ff;
    font-size: 18px;
    font-weight: 700;
  }
  .container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    > * {
      margin: 0 15px;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .item {
      cursor: pointer;
    }
    .icon {
      color: #3370ff;
    }
    .line {
      width: 0;
      width: 15px;
      border: 1px solid rgba(217, 217, 217, 100);
      position: relative;
      left: 18px;
    }

    .content {
      display: flex;
      align-items: center;
      border: none;
      span:nth-child(1) {
        // padding-top: 5px;
        position: relative;
        top: 2px;
      }
      .icon {
        color: #999999 !important;
      }
    }
  }
}
</style>
