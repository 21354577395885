<template>
  <div class="leftView" style="min-height: 777px;">
    <div class="logoView" @click="$router.push('/home/get')">
      <img :src="logo"/>
    </div>
    <div class="menuView">
      <div
        v-for="(item, index) in menuArr"
        :key="index"
        :class="$route.path.match(item.reg) ? 'menuItem select-item' : 'menuItem'"
        @click="$router.push(item.routePath)"
      >
        <div>
          <img
            v-if="$route.path.match(item.reg) && 'patch'"
            :src="item.activeIcon"
            alt
          />
          <img v-else :src="item.icon" alt />
          <p :style="$route.path.match(item.reg) ?'color: #3370ff':''">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <!-- <button class="btn-logout" @click="handleLogout">
      <img src="../assets/navImages/Menu_Logout.png" />
    </button> -->
  </div>
</template>

<script>
import logo from "_img/logo.png";
import shouYin_a from "@/assets/navImages/shouYin_a.png";
import shouYin from "@/assets/navImages/shouYin.png";
import yuYue_a from "@/assets/navImages/yuYue_a.png";
import yuYue from "@/assets/navImages/yuYue.png";
import orderImg from "@/assets/navImages/order.png";
import orderImg_a from "@/assets/navImages/order_a.png";

import applyCardImg from "@/assets/navImages/card.png";
import applyCardImg1 from "@/assets/navImages/card_a.png";

import recharge from "@/assets/navImages/recharge.png";
import recharge_a from "@/assets/navImages/recharge_a.png";

import user from "@/assets/navImages/user.png";
import user_a from "@/assets/navImages/user_a.png";

import shouZhi from "@/assets/navImages/shouZhi.png";
import shouZhi_a from "@/assets/navImages/shouZhi_a.png";

import tongJi from "@/assets/navImages/tongJi.png";
import tongJi_a from "@/assets/navImages/tongJi_a.png";

import moreImg from "@/assets/navImages/more.png";
import moreImg1 from "@/assets/navImages/more_a.png";

export default {
  name: "LeftMenu.vue",
  data() {
    return {
      logo,
      menuArr: [
        {
          name: "收银台",
          reg: /^\/home\/get/g,
          routePath: "/home/get",
          activeIcon: shouYin_a,
          icon: shouYin,
        },
        {
          name: "预约",
          reg: /^\/home\/reserve/g,
          routePath: "/home/reserve",
          activeIcon: yuYue_a,
          icon: yuYue,
        },
        {
          name: "订单",
          reg: /^\/home\/order/g,
          routePath: "/home/order",
          activeIcon: orderImg_a,
          icon: orderImg,
        },
        {
          name: "办卡",
          reg: /^\/home\/applyCard/g,
          routePath: "/home/applyCard",
          activeIcon: applyCardImg1,
          icon: applyCardImg,
        },
        {
          name: "充值",
          reg: /^\/home\/charger/g,
          routePath: "/home/charger",
          activeIcon: recharge_a,
          icon: recharge,
        },
        {
          name: '用户',
          reg: /^\/home\/user/g,
          routePath: '/home/user',
          activeIcon: user_a,
          icon: user
        },
        {
          name: "统计",
          reg: /^\/home\/report/g,
          routePath: "/home/report",
          activeIcon: tongJi_a,
          icon: tongJi,
        },
        {
          name: "收支",
          reg: /^\/home\/incomeExpenses/g,
          routePath: "/home/incomeExpenses",
          activeIcon: shouZhi_a,
          icon: shouZhi,
        },
        {
          name: "更多",
          reg: /^\/home\/more/g,
          routePath: "/home/more",
          activeIcon: moreImg1,
          icon: moreImg,
        },
      ],
    };
  },
  methods: {
    handleLogout() {
      // 退出登录
      this.$confirm("是否退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // this.$router.push({path: '/login'})
        this.$store.dispatch("user/logout").then(() => {
          location.reload();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.leftView {
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  top: 0;
  width: 76px;
  height: 100%;
  background: #fff;
  text-align: center;
  z-index: 200;

  > .logoView {
    padding: 10px 0;

    > img {
      width: 36px;
      height: 36px;
      border-radius: 20px;
      background-color: #3370ff;
    }
  }

  > .menuView {
    // margin-top: 15px;

    > .menuItem {
      position: relative;
      width: 100%;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 26px;
        height: 26px;
      }

      p {
        font-family: SFUIDisplay-Regular;
        font-size: 13px;
        color: #666;
      }
    }
    .select-item {
      border-radius: 5px;
      background: rgba(51, 112, 255, .1);
      width: 62px;
      margin: 0 auto;
    }
  }

  > .helpView {
    /* position: absolute; */
    left: 0;
    bottom: 55px;
    width: 100%;
    padding-top: 15px;

    > .menuItem {
      width: 100%;
      height: 50px;
      cursor: pointer;

      > img {
        width: 28px;
        height: 28px;
      }
    }
  }

  > .btn-logout {
    /* position: absolute; */
    left: 0;
    bottom: 0;
    width: 100%;
    height: 55px;
    text-align: center;

    > img {
      width: 28px;
    }
  }
}

button {
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

p {
  margin: 0;
  padding: 0;
}
</style>
